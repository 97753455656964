<template>
  <div class="resume-page">
    <!-- <van-loading v-show="loading" class="loading"></van-loading> -->

    <p class="form-tips">请填写以下各项</p>

    <van-steps :active="active">
      <van-step>基本信息</van-step>
      <van-step>基本信息英文</van-step>
      <van-step>其他附件</van-step>
    </van-steps>

    <div v-if="active === 0">
      <step :langType="1" :active="active" @updateActive="updateActive"></step>
    </div>

    <van-form v-if="active === 1" >
      <step :langType="2" :active="active" @updateActive="updateActive"></step>
    </van-form>

    <van-form v-if="active === 2" >
      <file :active="active" @updateActive="updateActive"></file>
    </van-form>

  </div>
</template>

<script>
import step from './step.vue'
import file from './file.vue'

export default {
  name: "index",
  components: {
    step,
    file
  },
  data() {
    return {
      active: 0,
    };
  },
  created() { },
  mounted() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    updateActive(active){
      this.active = active
    }
  },
};
</script>

<style scoped  lang="scss">
.resume-page {
  padding-bottom: 20px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
}

.work-page {
  text-align: left;
  padding-bottom: 65px;
}

.work-page .title {
  padding: 10px 20px;
  margin: 10px 0 0 0;
}

.submit-content {
  margin-top: 20px;
  display: flex;
  padding:0 10px;
}

.submit-btn {
  flex: 1;
  height: 44px;
  background: linear-gradient(90deg, #e95944 0%, #e95944 100%);
  border-radius: 22px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  border-color: #e95944;
}

.submit-btn.disabled span {
  opacity: 0.5;
}

.submit-btn-cancel span {
  display: block;
  text-align: center;
  line-height: 44px;
}

.field-focus::after {
  border-color: #e95944;
  transform: scaleY(1);
}

.experience-model-card {
  padding:10px;
  .experience-model-header {

    display: flex;
    align-items: center;

    p {
      font-weight: bold;
      margin-right:15px;
    }
  }
}

::v-deep {
  .submit-btn.van-button--disabled {
    opacity: 1;
  }

  .submit-btn.van-button--disabled span {
    opacity: 0.5;
  }

  .van-cell {
    display: block;
    line-height: 28px;
    padding: 13px 20px;
    font-size: 16px;
    color: #333;
    border-color: #f1f4f6;
  }

  .van-cell__title {
    font-size: 12px;
    color: #666;
    padding-bottom: 5px;
  }

  .field-arrow .van-cell__value {
    position: relative;
    padding-right: 20px;
  }

  .field-arrow .van-cell__value::after {
    display: block;
    content: "";
    width: 19px;
    height: 16px;
    background: url(../../assets/images/mine/icon-arrow.png);
    background-size: 19px 16px;
    position: absolute;
    right: 0;
    top: 0;
    margin: 8px 0;
  }

  .van-picker__confirm {
    color: #e95944;
  }

  .van-field__label {
    width: 28em;
  }

  .photo {

    .van-uploader__upload,
    .van-uploader__preview-image {
      width: 74px;
      height: 103px;
    }
  }

  .van-step--horizontal {
    text-align: left;
  }

  .van-step__icon--active,
  .van-step__icon--finish,
  .van-step__title--active,
  .van-step__title--finish {
    color: #e95944;
  }

  .van-step--finish .van-step__circle,
  .van-step--finish .van-step__line {
    background: #e95944;
  }

  .experience-table{
      .header{
        font-size:13px;
        font-weight:bold;
        border: 1px solid #ebedf0;
        .van-col{
          border-right: 1px solid #ebedf0;
          padding:5px 0;
          &:last-child{
            border:none;
          }
        }
        span{
          display:block;
          color:#e95944;
          font-size:12px;
        }
      }
      .list{
        font-size:13px;
        border: 1px solid #ebedf0;
        border-top:none;
        .van-col{
          border-right: 1px solid #ebedf0;
          &:last-child{
            border:none;
          }
        }
      }
    }
}

.form-tips {
  text-align: left;
  font-weight: bold;
  padding-left: 20px;
  margin-bottom: 10px;
  margin-top: 20px;
}

@media (max-width: 768px) {
  ::v-deep {
    .van-col--12 {
      width: 100%;
    }

    .edu-experience-table{
      // .van-col--6,.van-col--4,.van-col--3,.van-col--2{
      //   width:100%;
      // }
    }
  }
}

@media (min-width: 992px) {
  .form-tips {
    text-align: left;
    font-weight: bold;
    padding-left: 15px;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .resume-page {
    max-width: 900px;
    margin: 0 auto;
  }

  .submit-btn {
    max-width: 200px;
    margin: 0 auto;
  }

  .experience-model-card {
    padding: 10px;

    .experience-model-header {

      display: flex;
      align-items: center;

      p {
        font-weight: bold;
        margin-right: 15px;
      }
    }
  }

  ::v-deep {
    .photo {

      .van-uploader__upload,
      .van-uploader__preview-image {
        width: 148px !important;
        height: 206px !important;
      }
    }

    .upload-image {

      .van-uploader__upload,
      .van-uploader__preview-image {
        width: 200px !important;
        min-height: 100px !important;
        height: auto !important;
      }
    }

    .upload-video{
      .van-uploader__preview .van-uploader__file{
        width: 200px !important;
        min-height: 200px !important;
      }
    }

    .uploader-file {

      .van-uploader__file,
      .van-uploader__preview {
        width: 600px !important;
        height: 80px !important;
      }
    }

    .van-field__label {
      width: 40em !important;
    }

    .van-step {
      font-size: 14px;
    }

    .van-step--horizontal .van-step__title {
      font-size: 14px;
    }

    .van-steps--horizontal .van-steps__items {
      margin: 0 0 40px;
      padding-bottom: 25px;
    }

    .van-step--horizontal .van-step__circle-container {
      top: 40px;
    }

    .van-step--horizontal .van-step__line {
      top: 40px;
    }

    .van-step--horizontal .van-step__icon {
      font-size: 32px;
    }

    .experience-table{
      .header{
        font-size:13px;
        font-weight:bold;
        border: 1px solid #ebedf0;
        .van-col{
          border-right: 1px solid #ebedf0;
          padding:5px 0;
          &:last-child{
            border:none;
          }
        }
        span{
          display:block;
          color:#e95944;
          font-size:12px;
        }
      }
      .list{
        font-size:13px;
        border: 1px solid #ebedf0;
        border-top:none;
        .van-col{
          border-right: 1px solid #ebedf0;
          &:last-child{
            border:none;
          }
        }
      }
    }


  }
}
</style>
